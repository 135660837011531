import {
  onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import {getIndex} from '@core/utils'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/manuscript/itemStoreModule'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-manuscript'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })
  const blankItem = {
    id: null,
    title: null,
    sum_words: null,
    sum_pages: null,
    custom_pages: false,
    custom_price: false,
    custom_discount: false,
    public_price: null,
    sum_copies: store.state.app.settings.dafault_value_copies,
    discount_price: null,
    unit_price: null,
    transportation: null,
    receipt_date: null,
    response_date: null,
    proposition_date: null,
    source_id: null,
    author_id: null,
    genre_id: null,
    format_id: null,
    country_id: null,
    minor_author: false,
    particular_case: 'no',
    author: null/* {
      id: null,
      ful_name: null,
      email: null,
      author_ref: '',
      phone: null,
      avatar: null,
      source_id: null,
      status_id: null,
      user_id: null,
      addresses: [],
      password: null,
      changePassword: true,
    } */,
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const signatureForm = ref({})
  const canEdit = (th, td) => {
    if (td && th.value && td.editable) {
      td.classes = td.classes || {}
      td.classes[th.value] = {'w-100': true, 'h-100': true, 'add-padding': true}
    } else {

    }
  }
  const tableCommonColumns = [
    {
      text: 'Assistant lecture',
      value: 'assistant_lecture.code',
      fixed: true,
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '100px',
      sortable: false,
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'manuscripts.assistant_lecture_id',
        join_table: 'users',
        join_column: 'users.id',
        local_column: 'manuscripts.assistant_lecture_id',
      },
    },
    {
      text: 'Images couleur',
      value: 'images_couleur',
      align: 'center',
      cellClass: 'text-center',
      action: 'images_couleur',
      viewPermission: 'images_couleur',
      editPermission: 'edit_images_couleur',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Images couleur" ?',
      fields: [
        {
          type: 'select',
          label: 'Images couleur',
          value: 'images_couleur',
          required: false,
          items: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
          item_text: 'label',
          item_value: 'id',
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'}
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
        label: 'label',
        id: 'id',
        column: 'manuscripts.images_couleur',
        multiple: false,
      },
      filters: [
        {
          text: 'Images couleur',
          column: 'images_couleur',
          key: 'images_couleur',
          place_holder: 'Images couleur',
          type: 'select',
          values: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
          label: 'label',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'images_couleur',
          column: 'images_couleur',
        },
      ],
    },
    {
      text: 'Qualité',
      value: 'text_quality',
      align: 'center',
      blockBg: 'bg-grey',
      type: 'select',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      comment_column: 'text_quality',
      column_name: 'text_quality',
      editable: true,
      hideValue: false,
      selected_label: 'label',
      items: store.state.app.textQualities,
      info: 'Confirmez-vous que la vérification de la qualité a été faite ?',
      fields: [
        /* {
          type: 'date', label: 'Date traitement', value: 'quality_text', required: false,
        }, */
        {
          type: 'select',
          label: 'Qualité',
          value: 'text_quality',
          required: false,
          items: store.state.app.textQualities,
          item_text: 'title',
          item_value: 'id',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          text: 'Qualité du texte',
          column: 'text_quality',
          key: 'text_quality',
          place_holder: 'Qualité du texte',
          type: 'select',
          values: [...store.state.app.textQualities, {
            id: 'Null',
            label: 'Vide',
            title: 'Vide',
          }],
          label: 'title',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'manuscript_colors.column_name',
          join_table: 'manuscript_colors',
          join_column: 'manuscript_colors.manuscript_id',
          local_column: 'manuscripts.id',
          conditions: [
            {
              name: 'manuscript_colors.column_name',
              value: 'text_quality',
              single: true,
            },
            {
              name: 'manuscript_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'text_quality',
          column: 'text_quality',
        },
      ],
    },
    {
      text: 'Statut',
      value: 'status.id',
      align: 'center',
      width: '100px',
      cellClass: 'font-weight-bold',
      type: 'select',
      selected_value: 'status.id',
      selected_label: 'name',
      items: store.state.app.statuses.filter(ele => ele.status_type === 'manuscript'),
      comments: 'manuscript_comments',
      comment_column: 'status_id',
      column_name: 'status_id',
      editable: true,
      info: 'Ajouter un commentaire sur le statut de ce dossier ?',
      fields: [
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.statuses.filter(ele => ele.status_type === 'manuscript'),
        label: 'name',
        id: 'id',
        multiple: true,
        column: 'manuscripts.status_id',
        join_table: 'statuses',
        join_column: 'statuses.id',
        local_column: 'manuscripts.status_id',
      },
    },
    {
      text: 'Origine',
      value: 'origin.name',
      cellClass: 'text-center',
      action: 'origin.name',
      viewPermission: 'origin.name',
      editPermission: 'edit_origin.name',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Origine" ?',
      fields: [
        {
          type: 'text', label: 'Origine', value: 'origin.name', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.origins,
        label: 'name',
        id: 'id',
        multiple: true,
        column: 'manuscripts.origin_id',
        join_table: 'origins',
        join_column: 'origins.id',
        local_column: 'manuscripts.origin_id',
      },
    },
    {
      text: 'Titre',
      value: 'title',
      width: '200px',
      align: 'center',
      cellClass: 'text-center',
      action: 'title',
      viewPermission: 'title',
      editPermission: 'edit_title',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Titre" ?',
      fields: [
        {
          type: 'text', label: 'Titre', value: 'title', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {place_holder: '', type: 'text'},
    },

    {
      text: 'Ancien auteur',
      value: 'author.old_author',
      align: 'center',
      cellClass: 'text-center',
      action: 'author.old_author',
      viewPermission: 'author.old_author',
      editPermission: 'edit_author.old_author',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Ancien auteur" ?',
      fields: [
        {
          type: 'select',
          label: 'Ancien auteur',
          value: 'author.old_author',
          required: false,
          items: [{id: 1, label: 'Oui'}, {id: null, label: 'Non'}],
          item_text: 'label',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [{id: 1, label: 'Anciens auteurs'}, {id: 0, label: 'Nouveaux auteurs'}],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Genre',
      value: 'genre.label',
      align: 'center',
      cellClass: 'text-center',
      action: 'genre.label',
      viewPermission: 'genre',
      editPermission: 'edit_genre',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Genre" ?',
      fields: [
        {
          type: 'select',
          label: 'Genre',
          value: 'genre_id',
          required: false,
          items: store.state.app.genres,
          item_text: 'label',
          item_value: 'id',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filters: [
        {
          place_holder: '',
          type: 'select',
          values: store.state.app.genres,
          label: 'label',
          id: 'id',
          multiple: true,
          key: 'manuscript_genre_id',
          column: 'manuscripts.genre_id',
          join_table: 'genres',
          join_column: 'genres.id',
          local_column: 'manuscripts.genre_id',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_genre_id',
          column: 'genre_id',
        },
      ],
    },
    {
      text: 'Mots',
      value: 'sum_words',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Pages',
      value: 'sum_pages',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Format',
      value: 'format.name',
      align: 'center',
      cellClass: 'text-center',
      action: 'format.name',
      viewPermission: 'view_format.name',
      editPermission: 'edit_format.name',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Format" ?',
      fields: [
        {
          type: 'text', label: 'Format', value: 'format.name', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state['app-manuscript'].formats,
        label: 'name',
        id: 'id',
        multiple: true,
        column: 'manuscripts.format_id',
        join_table: 'formats',
        join_column: 'formats.id',
        local_column: 'manuscripts.format_id',
      },
    },
    {
      text: 'Prix Public',
      value: 'public_price',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: '40 ex',
      value: 'total_copies_price',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Prix remisé',
      value: 'discount_price',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Source',
      value: 'source.source_name',
      align: 'center',
      cellClass: 'text-center',
      action: 'source.source_name',
      viewPermission: 'source.source_name',
      editPermission: 'edit_source.source_name',
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      info: 'Confirmer le traitement de "Source" ?',
      fields: [
        /* {
          type: 'text', label: 'Source', value: 'source.source_name', required: false,
        }, */
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state['app-manuscript'].sources,
        label: 'source_name',
        id: 'id',
        multiple: true,
        column: 'manuscripts.source_id',
        join_table: 'sources',
        join_column: 'sources.id',
        local_column: 'manuscripts.source_id',
      },
    },
    {
      text: 'Date de réception',
      value: 'receipt_date',
      align: 'center',
      menu: false,
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      filter: {
        place_holder: 'Date1', type: 'date', range: true,
      },
      filters: [
        {
          key: 'receipt_date',
          text: 'Date de réception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_receipt_date',
          column: 'receipt_date',
        },
      ],
    },
    {
      text: 'Période',
      value: 'period',
      align: 'center',
      menu: false,
      filter: {
        place_holder: '',
        type: 'date',
        range: true,
        column: 'manuscripts.receipt_date',
        noLimit: true,
      },
      filters: [
        {
          key: 'period',
          text: 'Période',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'manuscripts.receipt_date',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_period',
          column: 'period',
        },
      ],
    },
    {
      text: 'Semaine',
      value: 'week',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Date de réponse',
      value: 'response_date',
      align: 'center',
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      menu: false,
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', range: true,
      },
      filters: [
        {
          key: 'response_date',
          text: 'Date de réponse',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_response_date',
          column: 'response_date',
        },
      ],
    },
    {
      text: 'Cas particulier',
      value: 'particular_case.label',
      column_name: 'particular_case_id',
      align: 'center',
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      editable: true,
      hideEdit: false,
      info: 'Ajouter une commentaire de "Cas particulier" ?',
      fields: [
        {
          type: 'select',
          label: 'Cas particulier',
          item_text: 'label',
          item_value: 'id',
          value: 'particular_case_id',
          items: store.getters['particularCase/particular_cases'],
        },
      ],
      functions: [
        (th, td) => {
          td.classes = td.classes || {}
          td.classes[th.value] = {'w-100': true, 'h-100': true, 'add-padding': true}
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particularCase/particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'particular_case',
        join_column: 'particular_case.id',
        local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Proposition',
      value: 'proposal_date',
      align: 'center',
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      menu: false,
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'proposal_date',
          text: 'Proposition',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_proposal_date',
          column: 'proposal_date',
        },
      ],
    },
    {
      text: 'Date de relance prévue',
      value: 'planned_relaunch_date',
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      align: 'center',
      menu: false,
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'planned_relaunch_date',
          text: 'Date de relance prévue',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_planned_relaunch_date',
          column: 'planned_relaunch_date',
        },
      ],
    },
    {
      text: 'Relance',
      value: 'relaunch_date',
      align: 'center',
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',
      menu: false,
      editable: true,
      hideEdit: false,
      info: 'Confirmer le traitement de "Auteur mineur" ?',
      fields: [
        {type: 'date', label: 'Date Relance', value: 'relaunch_date'},
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      functions: [
        (th, td) => {
          if (!td.proposal_date) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = {'w-100': true, 'h-100': true, 'add-padding': false}
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          }
        },
      ],
      filters: [
        {
          key: 'relaunch_date',
          text: 'Relance',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_relaunch_date',
          column: 'relaunch_date',
        },
      ],
    },
    {
      text: 'Publié',
      value: 'publish',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        column: 'manuscripts.publish',
        values: [{id: 1, label: 'Publié'}, {id: 0, label: 'En Traitement'}],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },

    /* { text: 'Date de publication', value: 'publish_date' }, */
    {
      text: 'Période de publication',
      value: 'publish_period',
      align: 'center',
      menu: false,

      /* filter: { place_holder: '', type: 'text' }, */
      filters: [
        {
          key: 'publish_period',
          text: 'Période de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          column: 'publish_date',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_publish_period',
          column: 'publish_period',
        },
      ],
    },
    {
      text: 'Retard de propal',
      value: 'propal_delay',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Retard relance',
      value: 'relaunch_delay',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },
    {
      text: 'Dossier publication',
      value: 'publication.id',
      align: 'center',
      filter: {place_holder: '', type: 'text'},
    },

  ]
  const tableColumns = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      class: 'fixed-column actions 50px l-50',
      cellClass: 'fixed-column actions 50px l-50 py-1',
      width: '50px',
      sortable: false,
    },
    {
      align: 'center',
      text: 'No',
      value: 'id',
      fixed: true,
      class: 'fixed-column no 100px l-100',
      cellClass: 'fixed-column no 100px py-1 l-100',
      width: '100px',
      filter: {place_holder: '', type: 'text'},
    },
    {
      align: 'center',
      text: 'Lecture',
      value: 'is_read',
      type: 'date',
      date_format: {month: 'numeric', day: 'numeric', year: 'numeric'},
      comments: 'manuscript_comments',
      colors: 'manuscript_colors',

      menu: false,
      editable: true,
      hideEdit: false,
      info: 'Confirmer le traitement de "lecture" ?',
      fields: [
        {type: 'date', label: 'Lecture', value: 'is_read'},
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      fixed: true,
      class: 'fixed-column Lecture 100px l-200',
      cellClass: 'fixed-column Lecture 100px l-200 text-center',
      width: '100px',
      filters: [
        {
          key: 'is_read',
          text: 'Lecture',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'manuscript_color.column_name',
          join_table: 'manuscript_colors',
          join_column: 'manuscript_colors.manuscript_id',
          local_column: 'manuscripts.id',
          conditions: [
            {
              name: 'manuscript_colors.column_name',
              value: 'is_read',
              single: true,
            },
            {
              name: 'manuscript_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            {id: 0, label: 'Est vide'},
            {id: 1, label: 'N\'est pas vide'},
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'manuscript_is_read',
          column: 'is_read',
        },

      ],
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column  author 150px l-300',
      cellClass: 'fixed-column author 150px l-300',
      width: '150px',
      filter: {
        place_holder: '',
        type: 'text',
        column: 'authors.full_name',
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'manuscripts.author_id',
      },
    },
    {
      text: 'Auteur mineur',
      value: 'author.minor_author',
      class: 'fixed-column Auteur mineur 50px l-450',
      cellClass: 'fixed-column Auteur mineur 50px l-450',
      width: '50px',
      type: 'check',
      trueValue: 1,
      falseValue: 0,
      colors: 'manuscript_colors',
      comments: 'manuscript_comments',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Auteur mineur" ?',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.manuscript_colors_ids.includes(el.id)),
        },
        {type: 'textarea', label: 'Commentaire', value: 'comment'},
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Mail',
      value: 'author.email',
      fixed: true,
      class: 'fixed-column 200px l-500',
      cellClass: 'fixed-column 200px l-500',
      width: '150px',
      filter: {
        place_holder: '',
        type: 'text',
        column: 'authors.email',
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'manuscripts.author_id',
      },
    },
    {
      align: 'center',
      text: 'Pays',
      value: 'author.country.full_name',
      class: 'fixed-column l-650 b-right 75px',
      cellClass: 'fixed-column l-650 b-right 75px',
      width: '75px',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'authors.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'authors.country_id',
      },
    },

    ...tableCommonColumns,

  ]
  const tableColumnsAuthorView = [
    {
      align: 'center',
      text: 'No',
      value: 'id',
      fixed: true,
      class: 'fixed-column 50px',
      cellClass: 'fixed-column 50px py-1',
      width: '100px',
      filter: {place_holder: '', type: 'text'},
    },
    {
      align: 'center',
      text: 'Lecture',
      value: 'is_read',
      fixed: true,
      class: 'fixed-column 50px l-100',
      cellClass: 'fixed-column 50px l-100 ',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: true,
        values: [
          {id: 'A', name: 'A'},
          {id: 'B', name: 'B'},
          {id: 'C', name: 'C'},
          {id: 'D', name: 'D'},
        ],
      },
    },
    {
      text: 'Mail',
      value: 'author.email',
      fixed: true,
      class: 'fixed-column 150px l-150',
      cellClass: 'fixed-column 150px l-150',
      width: '150px',
      filter: {
        place_holder: '',
        type: 'text',
        column: 'authors.email',
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'manuscripts.author_id',
      },
    },
    {
      align: 'center',
      text: 'Pays',
      value: 'author.country.full_name',
      class: 'fixed-column l-300 b-right 50px',
      cellClass: 'fixed-column l-300 b-right 50px',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'authors.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'authors.country_id',
      },
    },

    ...tableCommonColumns,
  ]

  const signatureTableColumns = [
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column',
      cellClass: 'fixed-column',
    },
    {
      value: 'author.full_name', text: 'Titre', class: 'fixed-column', cellClass: 'fixed-column',
    },
    {text: 'Date de signature', value: 'signature_date'},
  ]
  const collectTableColumns = [
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column',
      cellClass: 'fixed-column 200px',
      width: '200px',
    },
    {
      text: 'Titre',
      value: 'title',
      class: 'fixed-column l-200 b-right',
      cellClass: 'fixed-column l-200 b-right',
      width: '200px',
    },
    {text: 'Demande d\'éléments', value: 'request_elements'},
    {text: 'Photo auteur', value: 'author_avatar'},
    {text: 'Fichier Word', value: 'word_file'},
    {text: 'Biographie courte', value: 'short_biography'},
    {text: 'Résumé', value: 'resume'},
    {text: 'Adresse et téléphone', value: 'address_phone'},
    {text: 'Visuel - idée', value: 'visual_idea'},
    {text: 'Meilleure phrase', value: 'best_sentence'},
  ]
  const isSnackbarBottomVisible = ref(false)
  const isSuccessful = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isPropositionActive = ref(false)
  const isRelanceActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const accordDialog = ref(false)
  const downloadDialog = ref(false)
  const downloadObject = ref({loading: false, url: ''})
  const searchQuery = ref('')
  const authorFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const tabFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const manuscriptStates = ref({
    CREATED: 0,
    APPROVED: 1,
    SIGNED: 2,
    COLLECTED: 3,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }
  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  /* filters.value = authorFilter.value != null ? tableColumnsAuthorView : tableColumns */

  // new item
  const openDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }
  const openPropositionDialog = () => {
    isPropositionActive.value = !isPropositionActive.value
  }
  const openRelanceDialog = () => {
    isRelanceActive.value = !isRelanceActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = JSON.parse(JSON.stringify(val))
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }

    // item.value = JSON.parse(JSON.stringify(val))
    item.value = val
    accordDialog.value = true
  }

  // fetch data
  const fetchItems = (download, accord) => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isPropositionActive.value = false
    isRelanceActive.value = false
    if (download && download !== 'accord') {
      downloadObject.value = {loading: true, url: null}
    }
    if (download === 'accord') {
      isSuccessful.value = true
      snackBarMessage.value = accord
      download = null
    }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.value,
      values: null
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({...ele, values: null/* , type: null */})))
    }

    const columns = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.')).map(ele => ele.value)
    columns.push('author_id')
    columns.push('genre_id')
    columns.push('origin_id')
    columns.push('format_id')
    columns.push('source_id')
    columns.push('particular_case_id')
    columns.push('status_id')
    columns.push('assistant_lecture_id')

    store
      .dispatch('app-manuscript/fetchItems', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })), */
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        pay: payFilter.value,
        author: authorFilter.value,
        tab: tabFilter.value,
        columns,
        download,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const {data, total} = response.data
          items.value = data.map(ele => {
            ele.custom_discount = ele.custom_discount || false

            return ele
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-manuscript/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
        loading.value = false
      })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, {deep: true})
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, {deep: true})

  const saved = data => {
    items.value[data.index] = data.item
  }

  return {
    saved,
    manuscriptStates,
    item,
    signatureForm,
    collectTableColumns,
    items,
    downloadDialog,
    downloadObject,
    filters,
    tableColumns: authorFilter.value != null ? tableColumnsAuthorView : tableColumns,
    signatureTableColumns,
    searchQuery,
    tabFilter,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    select,
    isFormActive,
    isPropositionActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    isSuccessful,
    signatureDialog,
    accordDialog,
    isRelanceActive,
    openRelanceDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openPropositionDialog,
    deleteItem,
    deleteDialog,
    confirmDelete,
    fetchItems,
  }
}
